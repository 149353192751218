import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { CONTACT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import Hero from '@/components/Club/HeroClub';
import CharretteStory from '@/components/Club/CharretteStory';
import CtaBlock from '@/components/CtaBlock';

export default function Club({ data }) {
  const { t } = useTranslation();

  return (
    <Layout
      title="Club"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
    >
      <Hero />
      <CharretteStory />
      <CtaBlock
        title={t('ctaBlockTitle')}
        to={CONTACT}
        linkTitle={t('ctaBlockLink')}
        emoji={{
          image: getImage(data.chat),
          alt: 'chat icon',
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["club", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    chat: file(relativePath: { eq: "chat.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
  }
`;
