import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import SubContent from '@/components/SubContent';

const CharretteStory = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      architects: file(relativePath: { eq: "charrette-architectes.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <SubContent>
      <h2>{t('storyTitle')}</h2>
      <p className="mt-20 mb-20 subcontent">{t('storyBlock1')}</p>
      <GatsbyImage
        image={getImage(data.architects)}
        alt="Architectes avec une charrette"
        className="rounded-3xl"
      />
      <p className="text-center mb-20 mt-6">
        Gazette de Saint-Germain-des-Prés
      </p>
      <p className="mt-20 mb-20 subcontent">{t('storyBlock2')}</p>
    </SubContent>
  );
};

export default CharretteStory;
