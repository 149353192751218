import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import { tradWithColors } from '@/utils/trad';

import Hero from '@/components/Hero';

const HeroClub = () => {
  const { t } = useTranslation();

  const htmlContent = useMemo(() => tradWithColors(t('clubHeroTitle')), []);

  const data = useStaticQuery(graphql`
    query {
      cc: file(relativePath: { eq: "cc.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Hero
      fullHeight
      title={htmlContent}
      subTitle={<div className="max-w-[700px]">{t('clubHeroSubtitle')}</div>}
      image={{
        image: getImage(data.cc),
        alt: 'charrette icon',
        className: 'w-[220px]',
      }}
    />
  );
};

export default HeroClub;
